import React from 'react'
import Helmet from 'react-helmet'
import Footer from '../components/Footer'
import Header from '../components/Header'
// import './all.sass'
import '../assets/css/font.css'
import '../assets/css/plugins.css'
import '../assets/css/style.css'
import useSiteMetadata from './SiteMetadata'

const TemplateWrapper = ({children}) => {
  const {title, description, host, image} = useSiteMetadata();
  const metaImagePath = `${host}${image}`;

  return (
    <div>
      <Helmet>
        <html lang="cs"/>
        <title>{title}</title>
        <link rel="canonical" href={host} />
        <meta name="description" content={description}/>
        <meta name="image" content={metaImagePath} />

        <link
          rel="apple-touch-icon"
          sizes="150x150"
          href="/img/logo-ajspajou-2020-11-150x150.png"
        />
        <link
          rel="icon"
          type="image/png"
          href="/img/logo-ajspajou-bile-2020-11-32x32.png"
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href="/img/logo-ajspajou-bile-2020-11-16x16.png"
          sizes="16x16"
        />

        <meta property="og:type" content="website"/>
        <meta property="og:title" content={title}/>
        <meta property="og:description" content={description}/>
        <meta property="og:url" content={host}/>
        <meta property="og:image" content={metaImagePath}/>

        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={metaImagePath} />

        <link href='/assets/css/font.css' rel='stylesheet'/>
        <link href='/assets/css/plugins.css' rel='stylesheet'/>
        <link href='/assets/css/style.css' rel='stylesheet'/>

        {/*<script src="/assets/js/cookies.js"></script>*/}
      </Helmet>
      <Header/>
      <main>
        <div className='top_bg'>
          <svg viewBox='0 9 100 6' width='100%' xmlns='http://www.w3.org/2000/svg'>
            <linearGradient id='fill_1'>
              <stop className='g_fill_1' offset='0%' stop-color='currentcolor'></stop>
              <stop className='g_fill_2' offset='100%' stop-color='currentcolor'></stop>
            </linearGradient>
            <path d='M0 0 V12 Q30 17 55 12 T100 11 V0z' fill='url(#fill_1)'></path>
            <clipPath id='clip-path'>
              <path d='M0 0 V12 Q30 17 55 12 T100 11 V0z'></path>
            </clipPath>
            <path clip-path='url(#clip-path)' d='M0 30 V15 Q30 3 60 15 V30z' fill='#fff' opacity='0.5'></path>
          </svg>
        </div>

        <div className='main_content uk-container'>
          {children}
        </div>

        <div className="bottom_bg">
          <svg viewBox="0 9 100 6" width="100%" xmlns="http://www.w3.org/2000/svg">
            <linearGradient id="fill_2">
              <stop className="g_fill_1" offset="0%" stop-color="currentcolor"></stop>
              <stop className="g_fill_2" offset="100%" stop-color="currentcolor"></stop>
            </linearGradient>
            <path d="M0 0 V12 Q30 17 55 12 T100 11 V0z" fill="url(#fill_2)"></path>
          </svg>
        </div>
      </main>
      <Footer/>

      <script src='/assets/js/jquery.js'></script>
      <script src='/assets/js/plugins.js'></script>
      <script src='/assets/js/script.js'></script>
      {/*<script src='/assets/js/widgets.js'></script>*/}
      {/*<script src='/assets/js/data.js'></script>*/}
    </div>
  )
}

export default TemplateWrapper
