import React from 'react'
import {Link} from 'gatsby'

const Header = class extends React.Component {
  render() {
    return (
      <header>

        <div className='topnav_wrapper'>
          <div className='uk-container'>
            <div className='topnav uk-panel uk-padding-small-bottom section' id='topnav'>
              <div className='widget LinkList' id='LinkList1'>
                <div className='uk-grid' data-uk-grid=''>
                  <div className='topnav_social_wrapper uk-width-expand uk-margin-remove'>
                    <div className='uk-align-right uk-margin-remove'>
                      <ul className='topnav_social uk-iconnav'>
                        <li><a className='uk-icon-button icon_facebook'
                               data-uk-tooltip='pos:bottom'
                               href='https://www.facebook.com/anglictinaspajou/'
                               target='_blank'
                               title='Sledujte mě na Facebooku'>
                          <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M11,10h2.6l0.4-3H11V5.3c0-0.9,0.2-1.5,1.5-1.5H14V1.1c-0.3,0-1-0.1-2.1-0.1C9.6,1,8,2.4,8,5v2H5.5v3H8v8h3V10z"/></svg>
                        </a></li>
                        <li><a className='uk-icon-button icon_instagram'
                               data-uk-tooltip='pos:bottom'
                               href='https://www.instagram.com/anglictinaspajou/'
                               target='_blank'
                               title='Sledujte mě na Instagramu'>
                          <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M13.55,1H6.46C3.45,1,1,3.44,1,6.44v7.12c0,3,2.45,5.44,5.46,5.44h7.08c3.02,0,5.46-2.44,5.46-5.44V6.44 C19.01,3.44,16.56,1,13.55,1z M17.5,14c0,1.93-1.57,3.5-3.5,3.5H6c-1.93,0-3.5-1.57-3.5-3.5V6c0-1.93,1.57-3.5,3.5-3.5h8 c1.93,0,3.5,1.57,3.5,3.5V14z"/><circle cx="14.87" cy="5.26" r="1.09"/><path d="M10.03,5.45c-2.55,0-4.63,2.06-4.63,4.6c0,2.55,2.07,4.61,4.63,4.61c2.56,0,4.63-2.061,4.63-4.61 C14.65,7.51,12.58,5.45,10.03,5.45L10.03,5.45L10.03,5.45z M10.08,13c-1.66,0-3-1.34-3-2.99c0-1.65,1.34-2.99,3-2.99s3,1.34,3,2.99 C13.08,11.66,11.74,13,10.08,13L10.08,13L10.08,13z"/></svg>
                        </a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='mainnav_wrapper' data-uk-sticky='sel-target:.mainnav;cls-active:uk-navbar-sticky'>
          <div className='uk-container'>
            <div className='mainnav uk-panel uk-grid section' id='mainnav'>
              <div className='widget Header uk-margin-remove uk-width-auto'>
                <div className='header-widget'>
                  <h1 className='clip'>
                    <a href='/' title='Angličtina s Pájou - úvodní stránka'><span>Angličtina s Pájou</span></a>
                  </h1>
                  <a className='header-image-wrapper' href='/'>
                    <img alt='Angličtina s Pájou' src='/assets/logo-ajspajou-bile-2020-11-100x100.png'/>
                    Angličtina s Pájou
                  </a>
                </div>
              </div>
              <div className='widget HTML uk-margin-remove uk-width-expand'>
                <nav className='mainnav_menu uk-navbar-container uk-align-right uk-margin-remove'
                     data-uk-navbar='delay-hide:400;align:left;mode:click'>
                  <input className="menu-btn" type="checkbox" id="menu-btn"/>
                  <label className="menu-icon" htmlFor="menu-btn"><span className="navicon"></span></label>
                  <ul className='uk-navbar-nav'>
                    <li><Link to="/obrazky/">Obrázky</Link></li>
                    <li><Link to="/tahaky/">Taháky</Link></li>
                    <li><Link to="/nabizim/">Nabízím</Link></li>
                    <li><Link to="/o-mne/">O mně</Link></li>
                    <li><Link to="/reference/">Reference</Link></li>
                    <li><Link to="/kontakt/">Kontakt</Link></li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>

      </header>
    )
  }
}

export default Header
