import React from 'react'
import { Link } from 'gatsby'

const Footer = class extends React.Component {
  render() {
    return (
        <footer>
          <div className='uk-container'>
            <div className='maingrid uk-grid'>
              <div className='copyrights uk-width-expand@m section' id='copyrights'>
                <div className='widget HTML' id='HTML2'>
                  <div className='widget-content'>Copyright &#169; {new Date().getFullYear()} <Link to="/">Angličtina s Pájou</Link></div>
                </div>
              </div>
            </div>
          </div>
        </footer>
    )
  }
}

export default Footer
